import { default as index6yLg2LXqjbMeta } from "/app/pages/active-work-sessions/index.vue?macro=true";
import { default as indexryMIhEy6m5Meta } from "/app/pages/content/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexl0I1MQ0LZZMeta } from "/app/pages/newsletter/index.vue?macro=true";
import { default as index66w7ukPLgbMeta } from "/app/pages/qr/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as occasionalGYDx2fzAgfMeta } from "/app/pages/tasks/occasional.vue?macro=true";
import { default as regularuuKgddBfLmMeta } from "/app/pages/tasks/regular.vue?macro=true";
import { default as typesUIXITPloO1Meta } from "/app/pages/tasks/types.vue?macro=true";
import { default as work_45sessionsphfKrhfYoPMeta } from "/app/pages/tasks/work-sessions.vue?macro=true";
import { default as contractsyqU2IGyBCPMeta } from "/app/pages/templates/contracts.vue?macro=true";
import { default as telegramG4HBxdRnckMeta } from "/app/pages/templates/telegram.vue?macro=true";
import { default as paymentsKoXJeFMHQEMeta } from "/app/pages/trainings/payments.vue?macro=true";
import { default as scheduleiRKyv5Lx3KMeta } from "/app/pages/trainings/schedule.vue?macro=true";
import { default as indexBlOoEYXY5YMeta } from "/app/pages/volunteers/index.vue?macro=true";
import { default as indextu4JlcfS2fMeta } from "/app/pages/watchdog/index.vue?macro=true";
export default [
  {
    name: index6yLg2LXqjbMeta?.name ?? "active-work-sessions",
    path: index6yLg2LXqjbMeta?.path ?? "/active-work-sessions",
    meta: index6yLg2LXqjbMeta || {},
    alias: index6yLg2LXqjbMeta?.alias || [],
    redirect: index6yLg2LXqjbMeta?.redirect || undefined,
    component: () => import("/app/pages/active-work-sessions/index.vue").then(m => m.default || m)
  },
  {
    name: indexryMIhEy6m5Meta?.name ?? "content",
    path: indexryMIhEy6m5Meta?.path ?? "/content",
    meta: indexryMIhEy6m5Meta || {},
    alias: indexryMIhEy6m5Meta?.alias || [],
    redirect: indexryMIhEy6m5Meta?.redirect || undefined,
    component: () => import("/app/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexl0I1MQ0LZZMeta?.name ?? "newsletter",
    path: indexl0I1MQ0LZZMeta?.path ?? "/newsletter",
    meta: indexl0I1MQ0LZZMeta || {},
    alias: indexl0I1MQ0LZZMeta?.alias || [],
    redirect: indexl0I1MQ0LZZMeta?.redirect || undefined,
    component: () => import("/app/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: index66w7ukPLgbMeta?.name ?? "qr",
    path: index66w7ukPLgbMeta?.path ?? "/qr",
    meta: index66w7ukPLgbMeta || {},
    alias: index66w7ukPLgbMeta?.alias || [],
    redirect: index66w7ukPLgbMeta?.redirect || undefined,
    component: () => import("/app/pages/qr/index.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings",
    path: index11eXGkSJBcMeta?.path ?? "/settings",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: occasionalGYDx2fzAgfMeta?.name ?? "tasks-occasional",
    path: occasionalGYDx2fzAgfMeta?.path ?? "/tasks/occasional",
    meta: occasionalGYDx2fzAgfMeta || {},
    alias: occasionalGYDx2fzAgfMeta?.alias || [],
    redirect: occasionalGYDx2fzAgfMeta?.redirect || undefined,
    component: () => import("/app/pages/tasks/occasional.vue").then(m => m.default || m)
  },
  {
    name: regularuuKgddBfLmMeta?.name ?? "tasks-regular",
    path: regularuuKgddBfLmMeta?.path ?? "/tasks/regular",
    meta: regularuuKgddBfLmMeta || {},
    alias: regularuuKgddBfLmMeta?.alias || [],
    redirect: regularuuKgddBfLmMeta?.redirect || undefined,
    component: () => import("/app/pages/tasks/regular.vue").then(m => m.default || m)
  },
  {
    name: typesUIXITPloO1Meta?.name ?? "tasks-types",
    path: typesUIXITPloO1Meta?.path ?? "/tasks/types",
    meta: typesUIXITPloO1Meta || {},
    alias: typesUIXITPloO1Meta?.alias || [],
    redirect: typesUIXITPloO1Meta?.redirect || undefined,
    component: () => import("/app/pages/tasks/types.vue").then(m => m.default || m)
  },
  {
    name: work_45sessionsphfKrhfYoPMeta?.name ?? "tasks-work-sessions",
    path: work_45sessionsphfKrhfYoPMeta?.path ?? "/tasks/work-sessions",
    meta: work_45sessionsphfKrhfYoPMeta || {},
    alias: work_45sessionsphfKrhfYoPMeta?.alias || [],
    redirect: work_45sessionsphfKrhfYoPMeta?.redirect || undefined,
    component: () => import("/app/pages/tasks/work-sessions.vue").then(m => m.default || m)
  },
  {
    name: contractsyqU2IGyBCPMeta?.name ?? "templates-contracts",
    path: contractsyqU2IGyBCPMeta?.path ?? "/templates/contracts",
    meta: contractsyqU2IGyBCPMeta || {},
    alias: contractsyqU2IGyBCPMeta?.alias || [],
    redirect: contractsyqU2IGyBCPMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/contracts.vue").then(m => m.default || m)
  },
  {
    name: telegramG4HBxdRnckMeta?.name ?? "templates-telegram",
    path: telegramG4HBxdRnckMeta?.path ?? "/templates/telegram",
    meta: telegramG4HBxdRnckMeta || {},
    alias: telegramG4HBxdRnckMeta?.alias || [],
    redirect: telegramG4HBxdRnckMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/telegram.vue").then(m => m.default || m)
  },
  {
    name: paymentsKoXJeFMHQEMeta?.name ?? "trainings-payments",
    path: paymentsKoXJeFMHQEMeta?.path ?? "/trainings/payments",
    meta: paymentsKoXJeFMHQEMeta || {},
    alias: paymentsKoXJeFMHQEMeta?.alias || [],
    redirect: paymentsKoXJeFMHQEMeta?.redirect || undefined,
    component: () => import("/app/pages/trainings/payments.vue").then(m => m.default || m)
  },
  {
    name: scheduleiRKyv5Lx3KMeta?.name ?? "trainings-schedule",
    path: scheduleiRKyv5Lx3KMeta?.path ?? "/trainings/schedule",
    meta: scheduleiRKyv5Lx3KMeta || {},
    alias: scheduleiRKyv5Lx3KMeta?.alias || [],
    redirect: scheduleiRKyv5Lx3KMeta?.redirect || undefined,
    component: () => import("/app/pages/trainings/schedule.vue").then(m => m.default || m)
  },
  {
    name: indexBlOoEYXY5YMeta?.name ?? "volunteers",
    path: indexBlOoEYXY5YMeta?.path ?? "/volunteers",
    meta: indexBlOoEYXY5YMeta || {},
    alias: indexBlOoEYXY5YMeta?.alias || [],
    redirect: indexBlOoEYXY5YMeta?.redirect || undefined,
    component: () => import("/app/pages/volunteers/index.vue").then(m => m.default || m)
  },
  {
    name: indextu4JlcfS2fMeta?.name ?? "watchdog",
    path: indextu4JlcfS2fMeta?.path ?? "/watchdog",
    meta: indextu4JlcfS2fMeta || {},
    alias: indextu4JlcfS2fMeta?.alias || [],
    redirect: indextu4JlcfS2fMeta?.redirect || undefined,
    component: () => import("/app/pages/watchdog/index.vue").then(m => m.default || m)
  }
]