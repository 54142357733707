import { locale } from "dayjs";
import "dayjs/locale/lv";
import lvLV from "ant-design-vue/es/locale/lv_LV";
import enGB from "ant-design-vue/es/locale/en_GB";

export enum LangEnum {
  LV = "lv",
  EN = "en",
}

export const useLanguage = () => {
  const { $tolgee } = useNuxtApp();

  const setLanguage = (lang: LangEnum) => {
    $tolgee.changeLanguage(lang);

    locale(lang);
  };

  const getLanguage = (): LangEnum => {
    return $tolgee.getLanguage() as LangEnum;
  };

  const getLocale = () => {
    switch (getLanguage()) {
      case LangEnum.LV:
        return lvLV;
      case LangEnum.EN:
        return enGB;
    }
  };

  return {
    getLanguage,
    setLanguage,
    getLocale,
  };
};
