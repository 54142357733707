import {
  Tolgee,
  DevTools,
  VueTolgee,
  FormatSimple,
  TolgeeProvider,
  LanguageStorage,
} from "@tolgee/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  const tolgee = Tolgee()
    .use(LanguageStorage())
    .use(DevTools())
    .use(FormatSimple())
    .init({
      defaultLanguage: LangEnum.LV,
      defaultNs: "admin",
      ns: ["admin"],
      // for development
      apiUrl: runtimeConfig.public.tolgeeApiUrl,
      apiKey: runtimeConfig.public.tolgeeApiKey,
      // for production
      staticData: {
        "en:admin": () => import("../i18n/admin/en.json"),
        "lv:admin": () => import("../i18n/admin/lv.json"),
      },
    });

  nuxtApp.vueApp.use(VueTolgee, { tolgee });

  return {
    provide: {
      TolgeeProvider,
      tolgee,
    },
  };
});
