import { STORAGE_KEY } from "axios-jwt/dist/src/StorageKey";
import * as Sentry from "@sentry/browser";
import jwtDecode from "jwt-decode";

const isLoggedIn = (): boolean => {
  const tokens = localStorage.getItem(STORAGE_KEY);
  if (!tokens) {
    return false;
  }

  const refreshToken = JSON.parse(tokens as string);
  if (!refreshToken) {
    return false;
  }

  const accessToken = jwtDecode(refreshToken.accessToken) as {
    sub: string;
    role: string;
  };

  Sentry.setUser({ id: accessToken.sub });

  return true;
};

export default defineNuxtRouteMiddleware((to) => {
  if (to.path !== "/login" && !isLoggedIn()) {
    return navigateTo("/login");
  }
});
