import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/browser";

export default defineNuxtPlugin((nuxtApp) => {
  const environment = nuxtApp.$config.public.environment;

  if (
    environment === "prod" ||
    environment === "uat" ||
    environment === "sit"
  ) {
    Sentry.init({
      dsn: "https://d3f8b973461244aa8f0002e61c7a1ed7@o4505317226840064.ingest.sentry.io/4505317238177792",
      environment,
      sampleRate: 1,
    });
  }
});
